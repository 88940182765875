import {
  ROUTE_PROJECT_REPORT,
  ROUTE_PROJECT_REPORT_CREATE,
  ROUTE_PROJECT_REPORT_EDIT,
} from '@app/navigations/RoutePathConstant';
import {authLoader} from '@app/navigations/loader';
import React, {Suspense, lazy} from 'react';
import {Spin} from 'antd';

const ProjectReport = lazy(() =>
  import('../../../screens/ProjectReport')
    .then(module => ({default: module.ProjectReport})));

const CreateProjectReport = lazy(() =>
  import('../../../screensComponents/ProjectReport/ProjectReportScreen/CreateProjectReport')
    .then(module => ({default: module.CreateProjectReport})));

const EditProjectReport = lazy(() =>
  import('../../../screensComponents/ProjectReport/ProjectReportScreen/EditProjectReport')
    .then(module => ({default: module.EditProjectReport})));
const ProjectReportNavigation = [
  {
    path: ROUTE_PROJECT_REPORT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <ProjectReport />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PROJECT_REPORT_CREATE,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <CreateProjectReport />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PROJECT_REPORT_EDIT,
    loader: authLoader,
    element: (
      <Suspense fallback={<Spin />}>
        <EditProjectReport />
      </Suspense>
    ),
  },
];

export default ProjectReportNavigation;
